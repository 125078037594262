<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3554 8.12111L10.6916 13.8227L4.06064 9.74147
    C3.19176 9.20657 3.36787 7.88697 4.3467 7.60287L20.0022 3.04743
    C20.8925 2.78978 21.7156 3.62446 21.449 4.51889L16.804 20.1582
    C16.513 21.1369 15.2082 21.3064 14.6809 20.4325L10.6916 13.8227"
    :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Send',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>

<template>
  <div class="profile-page">
    <v-btn icon @click="$router.go(-1)" class="ml-minus-2">
      <v-icon>
        mdi-arrow-left
      </v-icon>
    </v-btn>
    <h1 class="my-5 font-weight-medium">{{ $t('views.Profile.title') }}</h1>
    <div v-if="loading">
      <v-skeleton-loader
        type="heading"
        :width="300"
        :height="50"
        class="rounded-lg"
      ></v-skeleton-loader>
      <v-row>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            type="heading"
            :width="200"
            class="rounded-lg"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="heading mt-3"
            :width="300"
            class="rounded-lg"
          ></v-skeleton-loader>
          <div class="d-flex mt-3">
            <v-skeleton-loader
              type="avatar"
              class="rounded-lg"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="avatar"
              class="rounded-lg ml-5"
            ></v-skeleton-loader>
          </div>
        </v-col>
        <v-col cols="12" md="6" align='right'>
          <v-skeleton-loader
            type="card"
            class="rounded-lg"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            type="card"
            :height="500"
            class="rounded-lg card-loader"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div class="d-flex flex-column flex-md-row justify-space-between">
        <div class="mt-3">
          <AccountBalance
            :title="$t('views.Profile.liquidity')"
            :totalBalance="balance.spendableCashBalance"
          />
          <div class="d-flex mt-8">
            <div class="d-flex flex-column align-center">
              <v-avatar color="primary" size="44">
                <v-btn icon @click="$router.push('/profile/deposit')">
                  <Bank color="#000000" />
                </v-btn>
              </v-avatar>
              <span class="grey--text small-text mt-1">{{ $t('views.Profile.deposit') }}</span>
            </div>
            <div class="d-flex flex-column align-center ml-10">
              <v-avatar color="primary" size="44">
                <v-btn icon @click="$router.push('/profile/withdraw')">
                  <Send color="#000000" />
                </v-btn>
              </v-avatar>
              <span class="grey--text small-text mt-1">{{ $t('views.Profile.pay') }}</span>
            </div>
          </div>
        </div>
        <ProfileCard />
      </div>
      <h3 class="font-weight-medium mt-5">
        {{ $t('views.Profile.documents') }}
      </h3>
      <SignedDocuments
        v-for="(date, index) in dateList"
        :key="index"
        :date="date"
        :events="eventByDates[date].slice().reverse()"
        class="mt-8"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import groupBy from 'lodash.groupby';
import lazyLoad from '@/utils/lazyLoad';
import Bank from '@/assets/icons/svg/Bank.vue';
import Send from '@/assets/icons/svg/Send.vue';

export default {
  name: 'Profile',
  components: {
    AccountBalance: lazyLoad('components/Shared/Cards/AccountBalance'),
    ProfileCard: lazyLoad('components/Shared/Cards/ProfileCard'),
    SignedDocuments: lazyLoad('components/Shared/Cards/SignedDocuments'),
    Bank,
    Send,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters('user', [
      'balance',
      'events',
    ]),
    eventByDates() {
      return groupBy(this.events, (event) => event.timestamp.substring(0, 7));
    },
    dateList() {
      return Object.keys(this.eventByDates).sort((a, b) => b.localeCompare(a));
    },
  },
  async created() {
    if (this.events.length > 0) {
      this.loading = false;
    }
    try {
      await Promise.all([
        this.getBalance(),
        this.listEvents(),
      ]);
    } catch (error) {
      this.$notify.error(error);
    }
    this.loading = false;
  },
  methods: {
    ...mapActions('user', [
      'getBalance',
      'listEvents',
    ]),
  },
};
</script>

<style lang="scss">
.profile-page {
  .small-text {
    font-size: 12px;
  }
  .card-loader {
    .v-skeleton-loader__image.v-skeleton-loader__bone {
      height: 400px;
    }
  }
}
</style>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.68328 5.65836L9.76393 2.11803
    C11.1716 1.41422 12.8284 1.41422 14.2361 2.11804L21.3167 5.65836
    C21.7355 5.86774 22 6.29574 22 6.76393
    C22 7.44659 21.4466 8 20.7639 8H3.23607
    C2.55341 8 2 7.44659 2 6.76393
    C2 6.29574 2.26452 5.86774 2.68328 5.65836Z"
    :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 11.5V18.5" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 11.5V18.5" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18 11.5V18.5" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21 22.75C21.4142 22.75 21.75 22.4142 21.75 22
    C21.75 21.5858 21.4142 21.25 21 21.25V22.75ZM3 21.25
    C2.58579 21.25 2.25 21.5858 2.25 22
    C2.25 22.4142 2.58579 22.75 3 22.75V21.25ZM21 21.25H3V22.75H21V21.25Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'Bank',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
